import React from 'react'
import { Link } from 'gatsby'
import Phone from './Phone'
import CTAStyles from '../../styles/CTA.module.sass'

/**
 * Display a call-to-action section
 *
 * @param {string} text Button label
 */
const CTA = props => {
  return (
    <div className={`cta ${CTAStyles.cta} ${props.class || ''}`}>
      <Link
        to="/contact/"
        className={`button is-link ${CTAStyles.button}`}
      >
        {props.text || 'Make an Enquiry'}
      </Link>
      <Phone class="phone with-icon is-size-5" />
    </div>
  )
}

export default CTA

/**
 * Create Netlify CMS Editor Component
 */
const CTAEditorComponent = {
  // Internal id of the component
  id: 'cta',
  // Visible label
  label: 'CTA',
  // Fields the user need to fill out when adding an instance of the component
  fields: [
    {
      name: 'text',
      label: 'Button Text',
      widget: 'string',
      default: 'Get a Quote',
    },
    {
      name: 'class',
      label: 'Class',
      widget: 'string',
      required: false,
      default: '',
    },
  ],
  // Pattern to identify a block as being an instance of this component
  pattern: /^<CTA text="(.*)" class="(.*)" \/>/,
  // Function to extract data elements from the regexp match
  fromBlock: function(match) {
    return {
      text: match[1],
      class: match[2],
    }
  },
  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview: function(obj) {
    return <CTA text={obj.text} class={obj.class} />
  },
  // Function to create a text block from an instance of this component
  toBlock: function(obj) {
    return '<CTA text="' + obj.text + '" class="' + obj.class + '" />'
  },
}

export { CTAEditorComponent }
