import React from 'react'
import { Link } from 'gatsby'
import FeaturedServicesStyles from '../../styles/FeaturedServices.module.sass'

/**
 * Display a section with Featured Services
 */
const FeaturedServices = () => {
  return (
    <div
      className={`container has-text-left ${FeaturedServicesStyles.featuredServices || ''}`}
    >
      <div className={`columns is-multiline ${FeaturedServicesStyles.columns || ''}`}>
        <div className={`column is-half is-two-fifths-desktop`}>
          <Link to="/#shop-fitter">
            <span className={`${FeaturedServicesStyles.label || ''} ${FeaturedServicesStyles.isBottom || ''}`}>
              <small>View</small>
              <strong>Shop <br />Fitouts</strong>
            </span>
            <img src="/media/shop-fitout-ireland.jpg" alt="commercial fitout" />
          </Link>
        </div>
        <div className={`column is-half is-three-fifths-desktop`}>
          <Link to="/restaurant-fitout">
            <span className={`${FeaturedServicesStyles.label || ''} ${FeaturedServicesStyles.isCenter || ''} ${FeaturedServicesStyles.isBottom || ''}`}>
              <small>View</small>
              <strong>Restaurant Fitouts</strong>
            </span>
            <img src="/media/restaurant-fitout.jpg" alt="restaurant fitout" />
          </Link>
        </div>

        <div className={`column is-half is-three-fifths-desktop`}>
          <Link to="/office-fitout">
            <span className={FeaturedServicesStyles.label || ''}>
              <small>View</small>
              <strong>Office Fitouts</strong>
            </span>
            <img src="/media/office-fitout.jpg" alt="office fitout" />
          </Link>
        </div>
        <div className={`column is-half is-two-fifths-desktop`}>
          <Link to="/hotel-fitout">
            <span className={`${FeaturedServicesStyles.label || ''} ${FeaturedServicesStyles.isBottom || ''}`}>
              <small>View</small>
              <strong>Hotel <br />Fitouts</strong>
            </span>
            <img src="/media/hotel-fitout.jpg" alt="hotel fitout" />
          </Link>
        </div>

        <div className={`column is-half is-two-fifths-desktop`}>
          <Link to="/reception-desks">
            <span className={FeaturedServicesStyles.label || ''}>
              <small>View</small>
              <strong>Reception <br />Desks</strong>
            </span>
            <img src="/media/reception-design-ireland.jpg" alt="reception desks" />
          </Link>
        </div>
        <div className={`column is-half is-three-fifths-desktop`}>
          <Link to="/exhibition-stands">
            <span className={`${FeaturedServicesStyles.label || ''} ${FeaturedServicesStyles.isCenter || ''} ${FeaturedServicesStyles.isBottom || ''}`}>
              <small>View</small>
              <strong>Exhibition Stands</strong>
            </span>
            <img src="/media/exhibition-stands.jpg" alt="exhibition stands" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default FeaturedServices