import React from 'react'
import { Link } from 'gatsby'
import Phone from './Phone'
import LargeCTAStyles from '../../styles/LargeCTA.module.sass'

/**
 * Display a call-to-action section
 *
 * @param {string} title
 * @param {string} text
 * @param {string} buttonText Button label
 */
const LargeCTA = props => {
  return (
    <div className={`large-cta ${LargeCTAStyles.cta}`}>
      <p className={`title is-4 ${LargeCTAStyles.title}`}>
        {props.title || 'Discuss your shop fitout with us'}
      </p>
      <p className={LargeCTAStyles.text}>
        {props.text ||
          'Complete shopfitting services for any retail unit, restaurant, hotel, office, clinic and more.'}
      </p>
      <div className={`buttons are-large ${LargeCTAStyles.buttons}`}>
        <Link to="/contact/" className="button is-link">
          {props.buttonText || 'Make an Enquiry'}
        </Link>
        <Phone class="button is-link is-inverted phone" />
      </div>
    </div>
  )
}

export default LargeCTA